'use client';
import { type Favorites, favoritesAtom } from '@/context/atoms/favorites/Favorites';
import { type Favorite } from '@/context/modules';
import { useAtom } from 'jotai';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

interface FavoritesContextState {
  favorites: Favorite[];
  addFavorite: (favorite: Favorite) => void;
  removeFavorite: (favorite: Favorite) => void;
  hasFavorite: (favorite: Favorite) => boolean;
}

export const FavoritesContext = createContext<FavoritesContextState | null>(null);

export function useFavorites(): FavoritesContextState {
  const context = useContext(FavoritesContext);
  if (context === null) {
    throw new Error('useFavorites must be used within FavoritesProvider');
  }

  return context;
}

interface FavoritesProviderProps {
  children: React.ReactNode;
}

export function FavoritesProvider({ children }: FavoritesProviderProps): React.JSX.Element {
  const [favConfig] = useState<keyof Favorites>('local'); // for later use with user favorites from backend
  const [favorites] = useAtom(favoritesAtom);
  const [localFavorites, setLocalFavorites] = useAtom(favorites.local);
  const [userFavorites, setUserFavorites] = useAtom(favorites.user);

  const currentFavorites = useMemo(() => {
    return favConfig === 'local' ? localFavorites : userFavorites;
  }, [favConfig, localFavorites, userFavorites]);

  const addFavorite = useCallback(
    (favorite: Favorite): void => {
      const newState = [...currentFavorites, favorite];
      if (favConfig === 'local') {
        setLocalFavorites(newState);
      } else {
        setUserFavorites(newState);
      }
    },
    [currentFavorites, favConfig, setLocalFavorites, setUserFavorites],
  );

  const removeFavorite = useCallback(
    (favorite: Favorite): void => {
      const newState = currentFavorites.filter(
        (f) =>
          f.type !== favorite.type ||
          f.identifier.name !== favorite.identifier.name ||
          f.identifier.zone?.name !== favorite.identifier.zone?.name ||
          f.identifier.zone?.coordinate !== favorite.identifier.zone?.coordinate,
      );
      if (favConfig === 'local') {
        setLocalFavorites(newState);
      } else {
        setUserFavorites(newState);
      }
    },
    [currentFavorites, favConfig, setLocalFavorites, setUserFavorites],
  );

  const hasFavorite = useCallback(
    (favorite: Favorite): boolean => {
      return currentFavorites.some(
        (f) =>
          f.type === favorite.type &&
          f.identifier.name === favorite.identifier.name &&
          f.identifier.zone?.name === favorite.identifier.zone?.name &&
          f.identifier.zone?.coordinate === favorite.identifier.zone?.coordinate,
      );
    },
    [currentFavorites],
  );

  return (
    <FavoritesContext.Provider
      value={{
        favorites: currentFavorites,
        addFavorite,
        removeFavorite,
        hasFavorite,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
}
