'use client';
import ThemeRegistry from '@/components/emotion/ThemeRegistry';
import ExternalScannerProvider from '@/components/ExternalScannerProvider';
import { FavoritesProvider } from '@/components/favorites/FavoritesProvider';
import AtomProvider from '@/components/jotai/AtomProvider';
import { SocketIOProvider } from '@/components/socketio/SocketIOProvider';
import { SnackbarProvider } from 'notistack';
import React from 'react';

export default function AppProviders({ children }: { children: React.ReactNode }): React.JSX.Element {
  return (
    <AtomProvider>
      <ThemeRegistry options={{ key: 'mui', prepend: false }}>
        {/* TO KEEP BETTER UX, DON'T USE preventDuplicate ON SnackbarProvider */}
        {/* USE preventDuplicate AS AN enqueueSnackbar OPTION */}
        {/* OR USE THE useSingleSnackbar HOOK */}
        <SnackbarProvider maxSnack={5} dense style={{ marginBottom: '20%' }}>
          <SocketIOProvider>
            <ExternalScannerProvider>
              <FavoritesProvider>{children}</FavoritesProvider>
            </ExternalScannerProvider>
          </SocketIOProvider>
        </SnackbarProvider>
      </ThemeRegistry>
    </AtomProvider>
  );
}
