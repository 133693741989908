import { atomWithLocalStorage } from '@/context/atoms/helper/atomWithLocalStorage';
import { type Favorite } from '@/context/modules';
import { atom } from 'jotai';

export const favoritesLocalAtom = atomWithLocalStorage<Favorite[]>('EKKO_WORKERUI_FAVORITES', []);

export const favoritesUserAtom = atom<Favorite[]>([]);

export interface Favorites {
  local: typeof favoritesLocalAtom;
  user: typeof favoritesUserAtom;
}

export const favoritesAtom = atom<Favorites>({ local: favoritesLocalAtom, user: favoritesUserAtom });
